<template>
    <div class="employee_info" v-loading="loading">
        <div class="form_flex">
            <div class="form_item ">
                <div class="titles"><span>*</span>姓名</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>手机号</div>
                <div class="inputs1">
                    {{form.mobile}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>员工号</div>
                <div class="inputs1">
                    {{form.gonghao}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>曾用名</div>
                <div class="inputs1">
                    {{form.real_name}}
                </div>
            </div>

            <div class="form_item ">
                <div class="titles"><span>*</span>证件类型及证件号</div>
                <div class="inputs1">
                    {{form.id_type}}  {{ form.id_card }}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>身份证有效期</div>
                <div class="inputs1">
                    {{form.id_card_time}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>性别</div>
                <div class="inputs1">
                    {{form.sex}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>出生日期(档案)</div>
                <div class="inputs1">
                    {{form.birthday2}}
                </div>
            </div>
            
            <div class="form_item ">
                <div class="titles"><span>*</span>民族</div>
                <div class="inputs1">
                    {{form.nation}}
                </div>
            </div>
            <!-- <div class="form_item ">
                <div class="titles"><span>*</span>出生日期(档案)</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div> -->
            <div class="form_item ">
                <div class="titles"><span>*</span>出生地</div>
                <div class="inputs1">
                    {{form.address}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>籍贯</div>
                <div class="inputs1">
                    {{form.native}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>户口性质</div>
                <div class="inputs1">
                    {{form.hkxz}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>户口所在地</div>
                <div class="inputs1">
                    {{form.hk_address}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>进入集团时间</div>
                <div class="inputs1">
                    {{form.jrjt_time}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>国籍或地区</div>
                <div class="inputs1">
                    {{form.guoji}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>健康状况</div>
                <div class="inputs1">
                    {{form.jiankang}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>进入本企业时间</div>
                <div class="inputs1">
                    {{form.jrqy_time}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>人员身份</div>
                <div class="inputs1">
                    {{form.shenfen}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>婚姻状况</div>
                <div class="inputs1">
                    {{form.hyzt}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>工作地点</div>
                <div class="inputs1">
                    {{form.gz_address}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>归侨侨眷标识</div>
                <div class="inputs1">
                    {{form.gqqjbs}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>复转军人标识</div>
                <div class="inputs1">
                    {{form.fzjrbs}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>职业危害/特殊工种</div>
                <div class="inputs1">
                    {{form.zywh}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>两代表一委员经历</div>
                <div class="inputs1">
                    {{form.ldbwy}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>熟悉专业有何专长</div>
                <div class="inputs1">
                    {{form.shgl}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>社会工龄扣除（月）</div>
                <div class="inputs1">
                    {{form.zyzc}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>参加工作时间</div>
                <div class="inputs1">
                    {{form.gz_time}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>转正时间</div>
                <div class="inputs1">
                    {{form.zz_time}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>集团工龄扣除（月）</div>
                <div class="inputs1">
                    {{form.jtglkcy}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>任职生效时间</div>
                <div class="inputs1">
                    {{form.rzyx_time}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>进入集团人员来源</div>
                <div class="inputs1">
                    {{form.jrjtryly}}
                </div>
            </div>
            
          <!--  <div class="form_item ">
                <div class="titles"><span>*</span>岗位一级组织</div>
                <div class="inputs1">
                    {{form}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>岗位二级组织</div>
                <div class="inputs1">
                    {{form}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>岗位三级组织</div>
                <div class="inputs1">
                    {{form}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>岗位四级组织</div>
                <div class="inputs1">
                    {{form}}
                </div>
            </div>
            
            <div class="form_item ">
                <div class="titles">岗位五级组织</div>
                <div class="inputs1">
                    {{form||'-'}}
                </div>
            </div> -->
						
            <div class="form_item form_bg">
                <div class="titles">部门</div>
                <div class="inputs1">
                    {{form.depart_title||'-'}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles">岗位</div>
                <div class="inputs1">
                    {{form.level6||'-'}}
                </div>
            </div>
            <!-- <div class="form_item form_bg">
                <div class="titles">岗位七级组织</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles">岗位八级组织</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div>
            
            <div class="form_item ">
                <div class="titles">岗位九级组织</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles">岗位十级组织</div>
                <div class="inputs1">
                    {{form.name}}
                </div>
            </div> -->
            <div class="form_item ">
                <div class="titles"><span>*</span>职级_序列名称</div>
                <div class="inputs1">
                    {{form.zjxlmc}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>职级_序列职级方案</div>
                <div class="inputs1">
                    {{form.zjxlfa}}
                </div>
            </div>
            
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>用工类型</div>
                <div class="inputs1">
                    {{form.yglx}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>职级_职级名称</div>
                <div class="inputs1">
                    {{form.zjmc}}
                </div>
            </div>
            <div class="form_item">
                <div class="titles"><span>*</span>职工类型</div>
                <div class="inputs1">
                    {{form.zglx}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>变动事务</div>
                <div class="inputs1">
                    {{form.bdsw}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>岗位状态</div>
                <div class="inputs1">
                    {{form.gwzt}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>排序码</div>
                <div class="inputs1">
                    {{form.sort}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>变动原因</div>
                <div class="inputs1">
                    {{form.bdyy}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>工作开始时间</div>
                <div class="inputs1">
                    {{form.work_start_at}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>学历</div>
                <div class="inputs1">
                    {{form.edu}}
                </div>
            </div>

            <div class="form_item ">
                <div class="titles"><span>*</span>奖金卡银行</div>
                <div class="inputs1">
                    {{form.jjkh_bank}}
                </div>
            </div>
            <div class="form_item ">
                <div class="titles"><span>*</span>奖金卡号</div>
                <div class="inputs1">
                    {{form.jjkh}}
                </div>
            </div>

            <div class="form_item form_bg">
                <div class="titles"><span>*</span>工资卡银行</div>
                <div class="inputs1">
                    {{form.gzkh_bank}}
                </div>
            </div>
            <div class="form_item form_bg">
                <div class="titles"><span>*</span>工资卡号</div>
                <div class="inputs1">
                    {{form.gzkh}}
                </div>
            </div>

            <div class="flex_btns">
                
                <div class="btns1" @click="handle_xgxx">修改信息</div>
                <div class="btns2" @click="handle_whjl">维护简历</div>
            </div>
            
        </div>
    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    
    data() {
        return {
            loading:false,
            form:{
                name:'',
            },
            zhlx_list:[
                {
                    title:123,
                }
            ]

        };
    },
    methods: {
        handle_xgxx(){
            this.$router.push({
                path:'/Employee_Information_add',
                query:{
                    id:this.$route.query.id
                }
            })
        },
        handle_whjl(){
            this.$router.push({
                path:'/Employee_Information_resume',
                query:{
                    id:this.$route.query.id
                }
            })
        },
        get_detial(){
            this.loading = true
            this.$api("getUser", {
                id:this.$route.query.id
            }, "get").then((res) => {
                console.log('详情',res)
                this.loading = false
                if (res.code == 200) {
                    this.form=res.data
                    
                } else {
                    alertErr(res.msg)
                }
            });
        }
         
    },
    computed: {
    },

    watch: {
        
    },


    created() {
        this.get_detial()


    },


};
</script>

<style scoped lang="less">
.employee_info {
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding:  0 9px;
    padding-bottom: 30px;
    .form_flex{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 57px;
        .form_item{
            width: 49.5%;
            display: flex;
            align-items: center;
            padding: 9px 0;
            .titles{
                width: 230px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                text-align: right;
                color: #4A4A4A;
                padding-right:11px;
                span{
                    color: #FF0000;
                    padding-right: 8px;
                }
            }
            .inputs1{
                width: 420px;
                height: 40px;
                border-radius: 0px 0px 0px 0px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                display: flex;
                align-items: center;
            }
        }

        .form_bg{
            background:  #F9F9F9;
        }
        .flex_btns{
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 230px;
            padding-top: 55px;
            .btns1{
                width: 179px;
                height: 44px;
                background: #2373C8;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 44px;
                cursor: pointer;
            }
            .btns2{
                width: 179px;
                height: 44px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #9D9D9D;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #4A4A4A;
                text-align: center;
                line-height: 44px;
                margin-left:18px;
            }
        }

        
    }
}
</style>